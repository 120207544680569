import * as React from 'react';
import Head from 'next/head';
import { Header } from '../../components/Header';
import { InterfaceModules } from '@brainysoft/lk-components';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { DisplayModeType } from '@brainysoft/lk-components';
import { useTranslation } from '../../utils/useTranslation';
import { useInterfaceModuleSetting } from '../../utils/useInterfaceModuleSetting';
import { useClient } from '../../queries/useClient';
import { useDisplayMode } from '../../utils/useDisplayMode';
import { AppContext } from '@brainysoft/lk-components';
import { useQueryClient } from 'react-query';
import { refreshActiveCredit } from '../../queries/useActiveCredit';
import { refreshCredits } from '../../queries/useCredits';
import { ILayout } from '@brainysoft/lk-components';
import { getCustomComponents } from '../../utils/getCustomComponents';
import { useSlotSurveys } from '../../utils/useSlotSurveys';
import { CustomerSurveys } from '../../components/Surveys/CustomerSurveys';
import { ActiveCreditWidget } from '../../components/ActiveCreditWidget';

const Layout: React.FC<React.PropsWithChildren<ILayout>> = (props) => {
  useDisplayMode();

  const { t } = useTranslation();

  const layoutStyles = useThemeStyles('Layout');

  const loanAppWidgetSettings = useInterfaceModuleSetting(InterfaceModules.CREATE_LOANAPP_WIDGET);
  const messageWidgetSettings = useInterfaceModuleSetting(InterfaceModules.MESSAGE_WIDGET);
  const disclaimerWidgetSettings = useInterfaceModuleSetting(InterfaceModules.DISCLAIMER_WIDGET);
  const footerSettings = useInterfaceModuleSetting(InterfaceModules.FOOTER);
  const clientQuery = useClient();
  const client = clientQuery.data;

  const { state } = AppContext.useContainer();
  const mode = state.displayMode;

  const showWidgets =
    loanAppWidgetSettings?.active || disclaimerWidgetSettings?.active || messageWidgetSettings?.active;
  const showFooter = !!footerSettings?.active;

  const Footer = getCustomComponents('Footer');

  const queryClient = useQueryClient();
  React.useEffect(() => {
    refreshActiveCredit(queryClient);
    refreshCredits(queryClient);
  }, []);

  const DisclaimerWidget = getCustomComponents('DisclaimerWidget');
  const MessageWidget = getCustomComponents('MessageWidget');

  const slotName = `mainpage`;
  const slotSurveys = useSlotSurveys(slotName);

  return (
    <React.Fragment>
      <Head>
        <title>{props.title}</title>
        <meta charSet='utf-8' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      </Head>
      <Header />
      <React.Fragment>
        {(mode === DisplayModeType.TABLET || mode === DisplayModeType.MOBILE) && (
          <div className={layoutStyles.greetingWrapper}>
            {t('greeting')}
            {client && client.firstName ? `, ${client.firstName}` : ''}!
          </div>
        )}
        <div
          className={`${showWidgets ? layoutStyles.generalWrapper : layoutStyles.generalWrapperExpanded} ${
            showFooter ? 'hasFooter' : ''
          }`}
        >
          <div className={showWidgets ? layoutStyles.contentWrapper : layoutStyles.contentWrapperExpanded}>
            {!!showWidgets && (
              <div className={layoutStyles.leftSide}>
                <div className={layoutStyles.topWidgets}>
                  {loanAppWidgetSettings?.active && <ActiveCreditWidget />}
                  {messageWidgetSettings?.active && <MessageWidget />}
                </div>
                {!!slotSurveys.length && <CustomerSurveys surveys={slotSurveys} slot={slotName} />}
                <div className={layoutStyles.bottomWidgets}>
                  {disclaimerWidgetSettings?.active && <DisclaimerWidget />}
                </div>
              </div>
            )}
            <div className={showWidgets ? layoutStyles.rightSide : layoutStyles.rightSideExpanded}>
              <div className={'contentSectionWrapper'}>{props.children}</div>
            </div>
          </div>
        </div>
        {showFooter && <Footer />}
      </React.Fragment>
    </React.Fragment>
  );
};

export default Layout;
